import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { noop } from 'lodash';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { useCoreIncentivesWizard, INVENTORY_TYPE } from 'site-modules/core-page/hooks/use-core-incentives-wizard';
import { IncentivesWizardEntities } from 'client/data/models/incentives-wizard';
import { STEP_FACTORY_COMPONENTS_MAP } from 'site-modules/shared/components/incentives/incentives-wizard/factories/step-factory-components-map';
import { getCreativeId } from 'site-modules/shared/components/incentives/incentives-wizard/utils/get-creative-id';
import { IncentivesWizardDrawer } from 'site-modules/shared/components/inventory/incentives-wizard/incentives-wizard-drawer/incentives-wizard-drawer';
import { PersistedStateWizardContent } from 'site-modules/shared/components/inventory/incentives-wizard/wizard-content/persisted-state-wizard-content';

export function CoreIncentivesWizardContent({
  incentives,
  setModelValue,
  zipCode,
  styleId,
  makeName,
  modelName,
  makeSlug,
  modelSlug,
  year,
  engineType,
  wizardState,
  onStartOver,
  onFinish,
  isUsed,
  isMobile,
}) {
  const [isModalOpen, toggleModalOpen] = useToggle(false);
  const wizardCreativeId = incentives && getCreativeId({ inventoryType: INVENTORY_TYPE[isUsed], incentives });
  const engineTypes = [engineType?.toLowerCase()];

  // TODO: Try moving IncentivesWizardDrawer to hook after core-6558
  const { openWizard, wizardQuestions, wizardWidgetCommonConfig, incentivesAmount } = useCoreIncentivesWizard({
    incentives,
    wizardState,
    styleId,
    year,
    makeSlug,
    modelSlug,
    makeName,
    engineTypes,
    isUsed,
    initialStepIndex: 0,
    setModelValue,
    toggleModalOpen,
  });

  return (
    <div className="core-incentives-wizard">
      {!incentivesAmount && <div>No rebates available in {zipCode}</div>}
      {!!incentivesAmount && (
        <Fragment>
          <Button
            color="link"
            size="lg"
            className="text-transform-none text-blue-50 px-0 py-0 fw-normal"
            onClick={openWizard}
            data-tracking-id="view_ev_eligibility_wizard"
          >
            {wizardWidgetCommonConfig.btnCopy}
          </Button>
          <IncentivesWizardDrawer
            questions={wizardQuestions}
            stepFactoryComponentsMap={STEP_FACTORY_COMPONENTS_MAP}
            onRebatesSaveSum={onFinish}
            onToggle={toggleModalOpen}
            isOpen={isModalOpen}
            isMobile={isMobile}
            make={makeName}
            model={modelName}
            year={parseInt(year, 10)}
            type={INVENTORY_TYPE[isUsed]}
            engineTypes={engineTypes}
            creativeId={wizardCreativeId}
            component={PersistedStateWizardContent}
            initialStepIndex={wizardWidgetCommonConfig.initialStepIndex}
            initialEligibleIncentivesIds={wizardState?.eligibleIncentiveIds}
            savedAnswers={wizardState?.answers}
            onStartOver={onStartOver}
          />
        </Fragment>
      )}
    </div>
  );
}

CoreIncentivesWizardContent.propTypes = {
  incentives: IncentivesWizardEntities.IncentivesData,
  setModelValue: PropTypes.func.isRequired,
  styleId: PropTypes.number.isRequired,
  makeName: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  makeSlug: PropTypes.string.isRequired,
  modelSlug: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  engineType: PropTypes.string,
  wizardState: IncentivesWizardEntities.IncentivesWizardUnwrappedPersistedState,
  onFinish: PropTypes.func,
  onStartOver: PropTypes.func,
  zipCode: PropTypes.string,
  isUsed: PropTypes.bool,
  isMobile: PropTypes.bool,
};

CoreIncentivesWizardContent.defaultProps = {
  incentives: null,
  engineType: null,
  wizardState: undefined,
  onStartOver: noop,
  onFinish: noop,
  zipCode: null,
  isUsed: false,
  isMobile: false,
};
